<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('customers_feature.customers_feature') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>


      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('customers_feature.customer_type') }}<span class="text-danger">*</span></label>
                <select name="customer_type" id="customer_type" v-model="data.customer_type" class="custom-select">
                  <option v-for="row in customer_type_list" :value="row.id" :key="row.id">{{ row.name }}</option>
                </select>
                <span v-if="validation && validation.customer_type" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.customer_type[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('customers_feature.status') }}</label>
                <b-form-checkbox size="lg" v-model="data.status" :name="'check-button-min'" switch :key="'a-1'"></b-form-checkbox>

                <span v-if="validation && validation.customer_type" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.customer_type[0] }}
                            </span>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="row mt-5">
        <div class="col-lg-12">
          <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
            <h6 class="mt-2">{{ $t('customers_feature.customer_advance_feature') }}</h6>
            <button type="button" class="btn btn-primary mr-2" @click="addToRepeater">{{ $t('add_more') }}</button>
          </div>
          <div class="bg-white">
            <table class="table" @keyup.alt.enter="addToRepeater" @keyup.alt.46="deleteFromRepeater(0)">
              <thead>
              <tr>
                <th width="300px">
                  <div class="d-flex justify-content-between">
                    <span>{{ $t('customers_feature.feature') }}</span>
                    <a href="/customers/customer-attributes" class="btn btn-primary btn-sm p-1" target="_blank">
                      <b-icon icon="plus"></b-icon>
                    </a>
                  </div>
                </th>
                <th width="15%">{{ $t('customers_feature.status') }}</th>
                <th width="20%" class="text-center">

                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(fe, index) in data_features" :key="index">
                <td>
                  <multiselect v-model="fe.feature"
                               :placeholder="$t('customers_feature.feature')"
                               label="name"
                               track-by="id"
                               :options="features"
                               :multiple="false"
                               :taggable="true"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                </td>
                <td class="text-center">
                  <b-form-checkbox size="lg" @change="changeStatus(fe.id, fe.status)" v-model="fe.status" :name="'check-button'+fe.id" switch :key="fe.id"></b-form-checkbox>
                </td>


                <td class="text-center">
                  <!--                                            <v-icon class="text-danger" @click="fe.id ? deleteItem(fe) : deleteFromRepeater(index)">mdi-delete</v-icon>-->
                  <v-icon class="text-danger" @click="deleteFromRepeater(index)">mdi-delete</v-icon>

                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>

    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "from-customers-features",
  data() {
    return {
      mainRoute: 'customers/customer-features',
      subMainRoute: 'customers/customer-feature',
      mainRouteDependency: 'base/dependency',
      features: [],

      idEdit: null,
      isEditing: false,
      validation: null,
      data: {
        customer_type: null,
        status: false,
      },
      repeater_features: {id: null, feature: null, status: false},
      data_features: [],
      customer_type_list: [
        {id: 1, name: this.$t('customer_type.company')},
        {id: 2, name: this.$t('customer_type.individual')},
      ],
    };
  },
  watch: {
    'data.customer_type': function (val) {
      if (val) {
        this.getData();
      }
    }
  },
  methods: {
    save() {
      // if (this.idEdit) {
      //     this.update();
      // } else {
      this.create();
      // }
    },

    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
        features: this.data_features,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.getData();
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    // update() {
    //
    //     ApiService.put(this.mainRoute + '/' + this.data.customer_type, {
    //         ...this.data,
    //         features: this.data_features,
    //     }).then((response) => {
    //         this.validation = null;
    //         this.$successAlert(response.data.message);
    //     }).catch((error) => {
    //         this.$errorAlert(error);
    //         this.validation = error.response ? error.response.data.errors : null;
    //     });
    // },

    async getData() {
      await ApiService.get(this.mainRoute, {params: {customer_type: this.data.customer_type}}).then((response) => {
        this.isEditing = true;
        if (response.data.data) {
          this.idEdit = response.data.data.id;

          this.data.customer_type = response.data.data.customer_type;
          this.data.status = response.data.data.status;

          if (!response.data.data.features) {
            this.data_features = [];
            this.addToRepeater();
          } else if (response.data.data.features && response.data.data.features.length <= 0) {
            this.data_features = [];
            this.addToRepeater();
          } else {
            this.data_features = response.data.data.features;
          }
        } else {
          this.data_features = [];
          this.addToRepeater();
        }
      });
    },

    getFeatures() {
      ApiService.get(this.mainRouteDependency + "/features").then((response) => {
        this.features = response.data.data;
      });
    },

    addToRepeater() {
      this.data_features.unshift(this.repeater_features);
      this.repeater_features = {id: null, feature: null, status: false};
    },
    deleteFromRepeater(index) {
      this.data_features.splice(index, 1);
    },

    // actionDelete(item) {
    //     ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
    //         this.getData();
    //         this.$successAlert(response.data.message)
    //     }).catch(error => {
    //         this.$errorAlert(error);
    //     });
    // },
    // deleteItem(item) {
    //     this.$confirmAlert('', this.actionDelete, item);
    // },
    changeStatus(id, status) {
      if (id) {
        ApiService.patch(this.subMainRoute + '/change-status/' + id, {
          status: (status ? '1' : '0'),
        }).then(response => {
          this.$successAlert(response.data.message);
        }).catch(error => {
          this.$errorAlert(error);
        });
      }

    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.customers_features")}]);
    this.getFeatures();
    this.addToRepeater();

    // let promise = this.getData();
    // let that = this;
    // Promise.all([promise]).then(() => {
    //     if (that.data_features.length <= 0) {
    //         that.addToRepeater();
    //     }
    // });


  },
};
</script>


